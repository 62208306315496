import { API_BASE_URL, SUBDOMAIN } from 'config'

export async function login(email, password) {
  const parameters = {
    method: 'POST',
    headers: {
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  }

  return fetch(`${API_BASE_URL}/login_check`, parameters)
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}

export async function change(token, password) {
  const parameters = {
    method: 'POST',
    headers: {
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({
      confirmationToken: token,
      plainPassword: password,
    }),
  }

  return fetch(`${API_BASE_URL}/change-password`, parameters)
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}
export async function forgot(email) {
  const parameters = {
    method: 'POST',
    headers: {
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({
      email,
    }),
  }

  return fetch(`${API_BASE_URL}/recover-password`, parameters)
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}

export async function list() {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
  }

  return fetch(`${API_BASE_URL}/users`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function updateProfile(userId, firstname, lastname, password) {
  const bearer = localStorage.getItem('app.Authorization')
  const body = {
    firstname,
    lastname,
  }
  if (password) {
    body.plainPassword = password
  }
  const parameters = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body),
  }

  return fetch(`${API_BASE_URL}/users/${userId}`, parameters)
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}
