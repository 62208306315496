import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import dashboard from 'services/dashboard'
import actions from './actions'

export function* GET() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: false,
    },
  })
  const response = yield call(dashboard)
  if (response.status === 200) {
    const data = yield response.json().then(json => {
      return json
    })

    const yearlyArray = []
    Object.keys(data.data.transactions.yearly).forEach(row => {
      yearlyArray.push(data.data.transactions.yearly[row])
    })

    const monthlyArray = []
    Object.keys(data.data.transactions.monthly).forEach(row => {
      monthlyArray.push(data.data.transactions.monthly[row])
    })

    const statements = {}
    Object.keys(data.data.statements).forEach(i => {
      const statementsRow = data.data.statements[i]
      const yearlyArrayRow = []
      Object.keys(statementsRow.yearly).forEach(row => {
        yearlyArrayRow.push(statementsRow.yearly[row])
      })

      const monthlyArrayRow = []
      Object.keys(statementsRow.monthly).forEach(row => {
        monthlyArrayRow.push(statementsRow.monthly[row])
      })

      const row = {
        statementCount: statementsRow.count,
        debit: statementsRow.debit,
        credit: statementsRow.credit,
        balance: statementsRow.balance,
        accountBalance: statementsRow.accountBalance,
        offlineRegister: statementsRow.offlineRegister,
        offlineRegisterBalance: statementsRow.offlineRegisterBalance,
        dividend: statementsRow.dividend,
        yearly: yearlyArrayRow,
        monthly: monthlyArrayRow,
      }
      statements[i] = row
    })
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        transactionStats: data.data.transactions,
        transactionCount: data.data.transactions.count,
        debit: data.data.transactions.debit,
        credit: data.data.transactions.credit,
        balance: data.data.transactions.balance,
        accountBalance: data.data.transactions.accountBalance,
        offlineRegister: data.data.transactions.offlineRegister,
        offlineRegisterBalance: data.data.transactions.offlineRegisterBalance,
        dividend: data.data.transactions.dividend,
        yearly: yearlyArray,
        monthly: monthlyArray,
        loading: false,
        statementStats: data.data.statementsAll,
        statementCount: data.data.statementsAll.count,
        statementBalance: data.data.statementsAll.balance,
        statementAccountBalance: data.data.statementsAll.accountBalance,
        statementDividend: data.data.statementsAll.dividend,
        statementOfflineRegister: data.data.statementsAll.offlineRegister,
        statementOfflineRegisterBalance: data.data.statementsAll.offlineRegisterBalance,
        statementCredit: data.data.statementsAll.credit,
        statementDebit: data.data.statementsAll.debit,
        statements,
      },
    })
    yield call(GENERATE_CHART, null, null)
  }
}
export function* GENERATE_CHART(user, dateRange) {
  const state = yield select()
  console.log(user, dateRange, state)

  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loadingChart: true,
    },
  })

  let chartKeys = []
  const chartSeries = []
  if (state.dashboard.statementStats.monthly) {
    chartKeys = Object.keys(state.dashboard.statementStats.monthly)
    const debitSeries = Object.keys(state.dashboard.statementStats.monthly).map(item => {
      const value = state.dashboard.statementStats.monthly[item].debit
      return { meta: `Monthly Debit`, value }
    })
    const creditSeries = Object.keys(state.dashboard.statementStats.monthly).map(item => {
      const value = state.dashboard.statementStats.monthly[item].credit
      return { meta: `Monthly Credit`, value }
    })
    const accountSeries = Object.keys(state.dashboard.statementStats.monthly).map(item => {
      const value = state.dashboard.statementStats.monthly[item].accountBalance
      return { meta: `Monthly Account Balance`, value }
    })
    const registerSeries = Object.keys(state.dashboard.statementStats.monthly).map(item => {
      const value = state.dashboard.statementStats.monthly[item].offlineRegisterBalance
      return { meta: `Monthly Register Balance`, value }
    })
    const dividendSeries = Object.keys(state.dashboard.statementStats.monthly).map(item => {
      const value = state.dashboard.statementStats.monthly[item].dividend
      return { meta: `Dividends this month`, value }
    })
    chartSeries.push(accountSeries)
    chartSeries.push(registerSeries)
    chartSeries.push(dividendSeries)
    chartSeries.push(debitSeries)
    chartSeries.push(creditSeries)
    console.log(chartKeys, chartSeries)
  }

  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loadingChart: false,
      statementChart: {
        labels: chartKeys,
        series: chartSeries,
      },
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET, GET), takeEvery(actions.GENERATE_CHART, GENERATE_CHART)])
}
