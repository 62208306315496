import { API_BASE_URL, SUBDOMAIN } from 'config'

export default async function list() {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
  }

  return fetch(`${API_BASE_URL}/split-rule`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}
