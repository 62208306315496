const actions = {
  SET_STATE: 'transaction/SET_STATE',
  LIST: 'transaction/LIST',
  IMPORT: 'transaction/IMPORT',
  PROCESS: 'transaction/PROCESS',
  ADD_DIVIDEND: 'transaction/ADD_DIVIDEND',
  UNPROCESS: 'transaction/UNPROCESS',
  UNPROCESS_LIST: 'transaction/UNPROCESS_LIST',
  TRANSACTION_SPLIT: 'transaction/TRANSACTION_SPLIT',
}

export default actions
