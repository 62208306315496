import {all, takeEvery, put, call, select} from 'redux-saga/effects'
import { notification } from 'antd'
import { change, login, forgot, list, updateProfile } from 'services/user'
import jwtDecode from 'jwt-decode'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const loginResponse = yield call(login, email, password)
  if (loginResponse && loginResponse.token) {
    const decoded = jwtDecode(loginResponse.token)

    localStorage.setItem('app.Authorized', true)
    localStorage.setItem('app.Authorization', loginResponse.token)
    localStorage.setItem('app.Role', '')
    localStorage.setItem('app.User.Id', decoded.id)
    localStorage.setItem('app.User.Email', decoded.username)
    localStorage.setItem('app.User.Firstname', decoded.firstname)
    localStorage.setItem('app.User.Lastname', decoded.lastname)

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  } else if (loginResponse && loginResponse.status === 401) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Error',
          description: 'Incorrect username and/or password',
          type: 'error',
          visible: true,
        },
        loading: false
      }
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Error',
          description: 'Something went wrong with your request. Please try again',
          type: 'error',
          visible: true,
        },
        loading: false
      }
    })
  }
}

export function* FORGOT({ payload }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const callResponse = yield call(forgot, email)
  if (callResponse && callResponse.code === 200) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Success',
          description: `If the user with email ${email} exists an email has been sent.`,
          type: 'success',
          visible: true,
        },
        loading: false
      }
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Error',
          description: 'Something went wrong with your request. Please try again',
          type: 'error',
          visible: true,
        },
        loading: false
      }
    })
  }
}

export function* CHANGE_PASSWORD({ payload }) {
  const { token, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const callResponse = yield call(change, token, password)
  if (callResponse && callResponse.code === 200) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Success',
          description: 'Your password has been successfully updated',
          type: 'success',
          visible: true,
        },
        loading: false
      }
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        formNotification: {
          message: 'Error',
          description: 'Something went wrong with your request. Please try again',
          type: 'error',
          visible: true,
        },
        loading: false
      }
    })
  }
}

export function* UPDATE_PROFILE({ payload }) {
  const { firstname, lastname, password } = payload
  const state = yield select()

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const callResponse = yield call(updateProfile, state.user.id, firstname, lastname, password)
  if (callResponse && callResponse.code === 200) {
    const decoded = jwtDecode(callResponse.data.token)

    localStorage.setItem('app.Authorized', true)
    localStorage.setItem('app.Authorization', callResponse.data.token)
    localStorage.setItem('app.Role', '')
    localStorage.setItem('app.User.Id', decoded.id)
    localStorage.setItem('app.User.Email', decoded.username)
    localStorage.setItem('app.User.Firstname', decoded.firstname)
    localStorage.setItem('app.User.Lastname', decoded.lastname)

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileForm: {
          message: 'Success',
          description: 'Your account has been successfully updated',
          type: 'success',
          visible: true,
          loading: false,
        },
      }
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileForm: {
          message: 'Error',
          description: 'Something went wrong with your request. Please try again',
          type: 'error',
          visible: true,
          loading: false,
        },
      }
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const token = localStorage.getItem('app.Authorization')
  let decoded = false
  if (localStorage.getItem('app.Authorized') && token) {
    decoded = jwtDecode(token)
  }
  if (decoded && decoded.exp * 1000 > Date.now()) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: localStorage.getItem('app.User.Id'),
        name: localStorage.getItem('app.User.Firstname'),
        firstname: localStorage.getItem('app.User.Firstname'),
        lastname: localStorage.getItem('app.User.Lastname'),
        email: localStorage.getItem('app.User.Email'),
        avatar: '',
        role: 'admin',
        authorized: localStorage.getItem('app.Authorized'),
        users: [],
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: null,
        name: null,
        firstname: '',
        lastname: '',
        email: null,
        avatar: '',
        role: null,
        authorized: false,
        users: [],
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  localStorage.setItem('app.Authorized', false)
  localStorage.setItem('app.Authorization', '')
  localStorage.setItem('app.Role', '')
  localStorage.setItem('app.User.Id', '')
  localStorage.setItem('app.User.Email', '')
  localStorage.setItem('app.User.Firstname', '')
  localStorage.setItem('app.User.Lastname', '')

  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      firstname: '',
      lastname: '',
      avatar: '',
      authorized: false,
      loading: false,
      users: [],
    },
  })
}


export function* LIST() {
  const response = yield call(list)
  if (response.status === 200) {
    const data = yield response.json().then(json => {
      return json;
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        users: data.data,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.FORGOT, FORGOT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
