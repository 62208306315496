import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import transaction from './transaction/sagas'
import splitRule from './splitRule/sagas'
import dashboard from './dashboard/sagas'
import statement from './statement/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), transaction(), splitRule(), dashboard(), statement()])
}
