import { API_BASE_URL, SUBDOMAIN } from 'config'

export async function list() {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
  }

  return fetch(`${API_BASE_URL}/transaction`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function latest() {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
  }

  return fetch(`${API_BASE_URL}/transaction`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function importTransactions(fileContent) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      data: fileContent,
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/import`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function processTransactions(splitRule, selectedTransactionItems) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      splitRule,
      transactions: selectedTransactionItems.map(transactionItem => transactionItem.id),
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/process`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function unprocessTransaction(transactionId) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      transactionId,
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/unprocess`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}
export async function unprocessTransactions(transactionIds) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      transactionIds: transactionIds.map(transactionItem => transactionItem.id),
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/unprocess`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function splitTransaction(split, selectedTransactionItems) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      split,
      transaction: selectedTransactionItems[0].id,
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/split`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}

export async function addDividendTransaction(identifier, date, amount, description) {
  const bearer = localStorage.getItem('app.Authorization')
  const parameters = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${bearer}`,
      Organisation: SUBDOMAIN,
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({
      identifier,
      date,
      debit: amount,
      description,
      paidFromOfflineRegister: true,
    }),
  }

  return fetch(`${API_BASE_URL}/transaction/dividend`, parameters)
    .then(response => {
      return response
    })
    .then(json => {
      return json
    })
}
