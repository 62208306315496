import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import moment from 'moment'
import statement from 'services/statement'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'statement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(statement)
  if (response.status === 200) {
    const data = yield response.json().then(json => {
      return json
    })

    yield put({
      type: 'statement/SET_STATE',
      payload: {
        list: data.data,
        listFiltered: data.data,
        loading: false,
      },
    })
  }
}

export function* FILTER_LIST({ payload }) {
  const { user, month } = payload
  const state = yield select()
  yield put({
    type: 'statement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loadingChart: true,
    },
  })

  let statementsFiltered = state.statement.list.filter(item => {
    if (month && moment(item.date).format('YYYYMM') > month.format('YYYYMM')) {
      return false
    }
    if (user && user !== 'all') {
      return item.user.id === user
    }
    return true
  })

  const chartKeys = []
  const chartSeries = []

  const accountSeries = []
  const registerSeries = []
  const dividendSeries = []
  const debitSeries = []
  const creditSeries = []
  let statementsFilteredBalance = 0
  let statementsFilteredCredit = 0
  let statementsFilteredDebit = 0
  let statementsFilteredAccountBalance = 0
  let statementsFilteredOfflineRegister = 0
  let statementsFilteredOfflineRegisterBalance = 0
  let statementsFilteredDividend = 0

  const monthKeys = []
  statementsFiltered.reverse().map(item => {
    const monthKey = moment(item.date).format('YYYY-MM')
    if (chartKeys.indexOf(monthKey) < 0) {
      chartKeys.push(monthKey)
    }
    if (monthKeys.indexOf(monthKey) < 0) {
      monthKeys.push(monthKey)
    }

    if (typeof debitSeries[monthKey] === 'undefined') {
      debitSeries[monthKey] = { meta: `Monthly Debit`, value: 0 }
      creditSeries[monthKey] = { meta: `Monthly Credit`, value: 0 }
      accountSeries[monthKey] = { meta: `Monthly Account Balance`, value: 0 }
      registerSeries[monthKey] = { meta: `Monthly Register Balance`, value: 0 }
      dividendSeries[monthKey] = { meta: `Dividend this month`, value: 0 }
    }
    // const total = item.credit - (item.splitRule.type === 'offline_register' ? 0 : item.debit);
    creditSeries[monthKey].value += item.credit

    // Offline register transactions will always be debit
    // Money withdrawn from account is stored in an offline company register
    // For safe keeping
    if (item.splitRule.type === 'offline_register') {
      registerSeries[monthKey].value += item.debit
    } else if (item.transaction.paidFromOfflineRegister) {
      dividendSeries[monthKey].value += item.debit
    } else {
      debitSeries[monthKey].value += item.debit
    }

    return item
  })

  monthKeys.forEach(index => {
    statementsFilteredAccountBalance +=
      creditSeries[index].value - debitSeries[index].value - registerSeries[index].value
    statementsFilteredDividend += dividendSeries[index].value
    statementsFilteredOfflineRegister += registerSeries[index].value
    statementsFilteredOfflineRegisterBalance +=
      registerSeries[index].value - dividendSeries[index].value
    statementsFilteredCredit += creditSeries[index].value
    statementsFilteredDebit += debitSeries[index].value
    statementsFilteredBalance +=
      creditSeries[index].value - debitSeries[index].value - dividendSeries[index].value

    accountSeries[index].value = statementsFilteredAccountBalance
    registerSeries[index].value = statementsFilteredOfflineRegisterBalance
  })

  chartSeries.push(Object.values(accountSeries))
  chartSeries.push(Object.values(registerSeries))
  chartSeries.push(Object.values(dividendSeries))
  chartSeries.push(Object.values(debitSeries))
  chartSeries.push(Object.values(creditSeries))
  console.log(chartKeys, chartSeries)
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loadingChart: false,
      statementChart: {
        labels: chartKeys,
        series: chartSeries,
      },
      statementBalance: statementsFilteredBalance,
      statementAccountBalance: statementsFilteredAccountBalance,
      statementDividend: statementsFilteredDividend,
      statementOfflineRegisterBalance: statementsFilteredOfflineRegisterBalance,
      statementOfflineRegister: statementsFilteredOfflineRegister,
      statementCount: statementsFiltered.length,
      statementCredit: statementsFilteredCredit,
      statementDebit: statementsFilteredDebit,
    },
  })

  statementsFiltered = statementsFiltered.reverse()

  yield put({
    type: 'statement/SET_STATE',
    payload: {
      loading: false,
      listFiltered: statementsFiltered,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST), takeEvery(actions.FILTER_LIST, FILTER_LIST)])
}
