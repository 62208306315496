import actions from './actions'

const initialState = {
  loading: true,
  transactionStats: [],
  transactionCount: 0,
  debit: 0,
  credit: 0,
  balance: 0,
  accountBalance: 0,
  offlineRegister: 0,
  offlineRegisterBalance: 0,
  dividend: 0,
  yearly: [],
  monthly: [],
  statements: {},
  statementStats: {
    count: 0,
    total: 0,
    totalDebit: 0,
    totalCredit: 0,
  },
  statementCount: 0,
  statementBalance: 0,
  statementDebit: 0,
  statementCredit: 0,
  statementDividend: 0,
  statementAccountBalance: 0,
  statementOfflineRegister: 0,
  statementOfflineRegisterBalance: 0,
  statementChart: {
    labels: [],
    series: [],
  },
  loadingChart: true,
}

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
