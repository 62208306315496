import {all, put, call, takeEvery} from 'redux-saga/effects'
import splitRule from 'services/splitRule'
import actions from "./actions";

export function* LIST() {
  const response = yield call(splitRule)
  if (response.status === 200) {
    const data = yield response.json().then(json => {
      return json;
    })

    yield put({
      type: 'splitRule/SET_STATE',
      payload: {
        list: data.data,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
  ])
}
