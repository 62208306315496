import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import transaction from './transaction/reducers'
import splitRule from './splitRule/reducers'
import dashboard from './dashboard/reducers'
import statement from './statement/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    transaction,
    splitRule,
    dashboard,
    statement,
  })
