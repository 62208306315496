import React from 'react'
import { Menu, Dropdown } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class StatsMenu extends React.Component {
  render() {
    const { user } = this.props

    const userLinks = user.users.map(userItem => (
      <Menu.Item key={userItem.id}>
        <Link to={`/stats/${userItem.id}`}>
          {userItem.firstname} {userItem.lastname}
        </Link>
      </Menu.Item>
    ))

    const menu = <Menu selectable={false}>{userLinks}</Menu>
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <div className={styles.dropdown}>
          <i className={`${styles.icon} icmn-stats-bars2`} />
          <span className="">
            <strong>
              <FormattedMessage id="topBar.stats" />
            </strong>
          </span>
        </div>
      </Dropdown>
    )
  }
}

export default StatsMenu
