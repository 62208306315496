import React from 'react'
import { Menu, Dropdown } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

class TransactionMenu extends React.Component {
  render() {
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <Link to="/transaction/list">Transaction List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/transaction/split-rule/list">Split Rules List</Link>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <div className={styles.dropdown}>
          <i className={`${styles.icon} icmn-stack`} />
          <span>
            <strong>
              <FormattedMessage id="topBar.transactions" />
            </strong>
          </span>
        </div>
      </Dropdown>
    )
  }
}

export default TransactionMenu
