const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  FORGOT: 'user/FORGOT',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  LIST: 'user/LIST',
  UPDATE_PROFILE: 'user/UPDATE_PROFILE',
}

export default actions
