export async function getLeftMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
  ]
}
export async function getTopMenuData() {
  return [
  ]
}

export async function getBreadcrumbMenuData() {
  return [
    {
      title: 'Transactions',
      key: 'transactions',
      url: '/transaction/list',
      children: [
        {
          key: 'splitRules',
          title: 'Split Rules',
          url: '/transaction/split-rule/list',
        },
      ],
    },
    {
      title: 'Statements',
      key: 'statements',
      url: '/statement/list',
    },
  ]
}
