import React from 'react'
import {Form, Input, Alert, Switch, Button, Modal, Icon} from 'antd';
import {connect} from "react-redux";

const FormItem = Form.Item
@connect(({ user }) => ({
  visible: user.profileModalVisible,
  profileForm: user.profileForm,
  email: user.email,
  firstname: user.firstname,
  lastname: user.lastname,
}))
@Form.create()
class UserProfileForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      changePasswordConfirm: false,
    }
  }

  changePassword = checked => {
    this.setState({
      changePasswordConfirm: checked,
    })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props
    if (value && value !== form.getFieldValue('password')) {
      callback('The password values do not match!')
    } else {
      callback()
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props
    if (value) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  handleCancel = () => {
    const {dispatch, form} = this.props
    form.resetFields()

    dispatch({
      type: 'user/SET_STATE',
      payload: {
        profileModalVisible: false
      },
    })
    dispatch({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }

  onSubmit = event => {
    event.preventDefault()
    const { form, dispatch, profileForm } = this.props

    form.validateFields((error, values) => {
      if (!error) {
        profileForm.submitted = true
        dispatch({
          type: 'user/SET_STATE',
          payload: {
            profileForm
          },
        })

        dispatch({
          type: 'user/UPDATE_PROFILE',
          payload: values
        })
      }
    })
  }

  render() {
    const {
      visible,
      form,
      email,
      firstname,
      lastname,
      profileForm,
    } = this.props

    const {changePasswordConfirm} = this.state

    return (
      <div>
        <Modal
          title="Profile Details"
          visible={visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form onSubmit={this.onSubmit}>
            {profileForm.visible && (
              <Alert
                message={profileForm.message}
                description={profileForm.description}
                type={profileForm.type}
              />
            )}

            <FormItem label="Email">
              {form.getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    required: true,
                    message: 'Please input email'
                  }
                ]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={email}
                  disabled
                  size="large"
                />)}
            </FormItem>

            <FormItem label="Firstname">
              {form.getFieldDecorator('firstname', {
                initialValue: firstname,
                rules: [
                  {
                    required: true,
                    message: 'Please input your firstname!'
                  }
                ]
              })(
                <Input
                  size="large"
                />)}
            </FormItem>

            <FormItem label="Lastname">
              {form.getFieldDecorator('lastname', {
                initialValue: lastname,
                rules: [
                  {
                    required: true,
                    message: 'Please input your lastname!'
                  }
                ]
              })(
                <Input
                  size="large"
                />)}
            </FormItem>

            <Switch
              size="default"
              onChange={this.changePassword}
              checkedChildren="Want to change my password"
              unCheckedChildren="Don't want to change my password"
            />
            <br />
            <br />
            {changePasswordConfirm && (
              <div>
                <FormItem>
                  {form.getFieldDecorator('password', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input password'
                      },
                      {
                        min: 6,
                        message: 'Your password should be at least 6 characters long.'
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                      size="large"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {form.getFieldDecorator('confirm', {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password'
                      },
                      {
                        min: 6,
                        message: 'Your password should be at least 6 characters long.'
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Confirm your password"
                      size="large"
                    />,
                  )}
                </FormItem>
              </div>
            )}
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button btn-block"
              loading={profileForm.submitted}
              size="large"
            >
              Update Account
            </Button>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default UserProfileForm
