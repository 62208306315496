import { all, put, call, takeEvery } from 'redux-saga/effects'
import md5 from 'md5'
import {
  list,
  importTransactions,
  processTransactions,
  unprocessTransaction,
  unprocessTransactions,
  splitTransaction,
  addDividendTransaction,
} from 'services/transaction'
import { message } from 'antd'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      tableListLoading: true,
    },
  })
  const response = yield call(list)
  if (response.status === 200) {
    const data = yield response.json().then(json => {
      return json
    })

    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        list: data.data,
        uploading: false,
        tableListLoading: false,
      },
    })
  } else {
    message.error(`There has been an error retrieving the transactions.`)
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        list: [],
        uploading: false,
        tableListLoading: false,
      },
    })
  }
}

export function* IMPORT({ payload }) {
  const { fileContent } = payload

  const response = yield call(importTransactions, fileContent)
  if (response.status === 201) {
    console.log(response)
    yield response.json().then(json => {
      return json
    })
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        uploading: false,
      },
    })
    message.success(`The transactions have been imported successfully.`)
    yield LIST()
  }
}

export function* PROCESS({ payload }) {
  const { splitRule, selectedTransactionItems } = payload
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      tableSelectedRowKeys: [],
    },
  })

  const response = yield call(processTransactions, splitRule, selectedTransactionItems)
  if (response.status === 201) {
    console.log(response)
    yield response.json().then(json => {
      return json
    })
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        uploading: false,
        tableSelectedRowKeys: [],
        selectedTransactionItems: [],
        transactionTableProcessDrawerVisible: false,
        transactionTableSelected: false,
        transactionTableProcessing: false,
      },
    })
    message.success(`The transactions have been processed successfully.`)
    yield LIST()
  }
}

export function* UNPROCESS_LIST({ payload }) {
  const { selectedTransactionItems } = payload
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      tableSelectedRowKeys: [],
    },
  })

  const response = yield call(unprocessTransactions, selectedTransactionItems)
  if (response.status === 200) {
    console.log(response)
    yield response.json().then(json => {
      return json
    })
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        uploading: false,
        tableSelectedRowKeys: [],
        selectedTransactionItems: [],
        transactionTableUnprocessDrawerVisible: false,
        transactionTableSelected: false,
        transactionTableProcessing: false,
        transactionTableUnprocessing: false,
      },
    })
    message.success(`The transactions have been unprocessed successfully.`)
  } else {
    message.error(`There has been an error with your unprocessing request.`)
  }
  yield LIST()
}

export function* UNPROCESS({ payload }) {
  const { transactionId } = payload

  const response = yield call(unprocessTransaction, transactionId)
  if (response.status === 200) {
    yield response.json().then(json => {
      return json
    })
    message.success(`The transaction has been unprocessed successfully.`)
  } else {
    message.error(`There has been an error with your unprocessing request.`)
  }
  yield LIST()
}

export function* TRANSACTION_SPLIT({ payload }) {
  const { split, selectedTransactionItems } = payload
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      tableSelectedRowKeys: [],
    },
  })

  const response = yield call(splitTransaction, split, selectedTransactionItems)
  if (response.status === 201) {
    console.log(response)
    yield response.json().then(json => {
      return json
    })
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        uploading: false,
        tableSelectedRowKeys: [],
        selectedTransactionItems: [],
        transactionTableSplitDrawerVisible: false,
        transactionSplitStatementsLoading: false,
        transactionTableSelected: false,
        transactionTableProcessing: false,
      },
    })
    message.success(`The transaction has been split successfully.`)
    yield LIST()
  }
}

export function* ADD_DIVIDEND({ payload }) {
  const { date, amount, description } = payload

  const identifier = md5(`${date}-${amount}-${description}`)
  const response = yield call(addDividendTransaction, identifier, date, amount, description)
  if (response.status === 201) {
    console.log(response)
    yield response.json().then(json => {
      return json
    })
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        transactionTableDividendDrawerVisible: false,
        transactionTableDividendProcessing: false,
      },
    })
    message.success(`The transaction has been saved successfully.`)
  } else {
    message.error(`There has been an error with your transaction request.`)

    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        transactionTableDividendProcessing: false,
      },
    })
  }
  yield LIST()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.IMPORT, IMPORT),
    takeEvery(actions.PROCESS, PROCESS),
    takeEvery(actions.UNPROCESS_LIST, UNPROCESS_LIST),
    takeEvery(actions.UNPROCESS, UNPROCESS),
    takeEvery(actions.TRANSACTION_SPLIT, TRANSACTION_SPLIT),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.ADD_DIVIDEND, ADD_DIVIDEND),
  ])
}
