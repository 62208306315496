import actions from './actions'

const initialState = {
  list: [],
  latest: [],
  uploading: false,
  tableListLoading: false,
  transactionTableSelected: 0,
  transactionTableCanProcess: false,
  transactionTableCanUnprocess: false,
  transactionTableCanSplit: false,
  transactionTableSelectedRecords: [],
  transactionTableProcessDrawerVisible: false,
  transactionTableSplitDrawerVisible: false,
  transactionTableUnprocessDrawerVisible: false,
  transactionTableDividendDrawerVisible: false,
  transactionTableProcessing: false,
  transactionTableUnprocessing: false,
  transactionTableDividendProcessing: false,
  tableSelectedRowKeys: [],
  transactionTableSplitDrawerValid: false,
  transactionSplitStatementsLoading: false,
  splitTotal: '0',
  splitRemaining: '',
  splitType: '',
  splitTotalHours: 0,
}

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
