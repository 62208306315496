import React from 'react'
import { Link } from 'react-router-dom'
import TransactionMenu from './TransactionMenu'
import StatementsMenu from './StatementsMenu'
import ProfileMenu from './ProfileMenu'
import StatsMenu from './StatsMenu'
import styles from './style.module.scss'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-4">
          <div className={styles.dropdown}>
            <i className={`${styles.icon} ${styles.topBarIcon} icmn-home`} />
            <span className="d-none d-xl-inline">
              <strong>
                <Link to="/"> Home</Link>
              </strong>
            </span>
          </div>
        </div>
        <div className="mr-4">
          <TransactionMenu />
        </div>
        <div className="mr-4">
          <StatementsMenu />
        </div>
        <div className="mr-4">
          <StatsMenu />
        </div>
        <div className={styles.profileMenu}>
          <ProfileMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
